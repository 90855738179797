import React, { Component, Suspense } from 'react';
import { IonApp, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { AppUrlListener, ErrorBoundary, MobileMenu, Navigation } from 'components';
import CookieConsent from 'react-cookie-consent';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Bootstrap v 4.4 */
import 'bootstrap/scss/bootstrap.scss';

import 'react-quill/dist/quill.snow.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './App.scss';

import { FlahaProps, FlahaState } from 'utils/Interfaces/Interfaces';
import { ar, fr } from 'lang';
import { IntlProvider } from 'react-intl';
import { constants, Events } from 'utils';
import { getLanguage, setLocalAppVersionUtil } from 'utils/Functions/Functions';
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import {
  saveCategoriesReduxUtils,
  saveCitiesReduxUtils,
  saveMarquesReduxUtils,
  saveModelesReduxUtils,
  saveParametragesReduxUtils,
  saveProvincesReduxUtils,
  saveRegionsReduxUtils,
} from 'utils/Functions/ReferentialGetters';
import { version } from '../package.json';
import { connect } from 'react-redux';
import CampaignSideBarButton from 'components/CampaignSideBarButton/CampaignSideBarButton';
import { CookiesProvider } from 'react-cookie';
import WebPopupBanner from 'components/WebPopupBanner/WebPopupBanner';
import ReactPixel from 'react-facebook-pixel';

const { SplashScreen, Device } = Plugins;

const androidAppVersionKey = '@flaha.androidAppVersion';
const webAppVersionKey = '@flaha.webAppVersion';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

class App extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    ReactPixel.init('386003348642756', null, options);
    ReactPixel.pageView();

    this.state = {
      lang: 'ar',
      referencialDataLoaded: false,
    };
    this.clearCache();
    this.fetchReferencialData();
    // this.getAndroidAppVersion();
  }

  UNSAFE_componentWillMount(): void {
    const SplashScreenHideOptions = { fadeOutDuration: 2500 };
    SplashScreen.hide(SplashScreenHideOptions);
    getLanguage()
      .then((lang) => {
        if (lang && (lang === 'fr' || lang === 'ar')) {
          this.setState(
            {
              lang: lang,
            },
            () => {
              Events.publish(constants.lang_change, { lang: lang });
            }
          );
        }
      })
      .catch((error) => {
        console.error(`Error has been thrown (${error.message})`, error);
      });
  }

  async getAndroidAppVersion() {
    const deviceInfo = await Device.getInfo();
    window.localStorage.setItem(androidAppVersionKey, deviceInfo.appVersion);
    if (isPlatform('capacitor')) {
      setLocalAppVersionUtil(deviceInfo.appVersion);
    }
  }

  async fetchReferencialData() {
    const { marquesRedux, modelesRedux } = this.props;
    await Promise.all([
      saveCategoriesReduxUtils(),
      saveCitiesReduxUtils(),
      saveProvincesReduxUtils(),
      saveRegionsReduxUtils(),
      saveParametragesReduxUtils(),
    ]);

    if (marquesRedux?.length <= 0) await saveMarquesReduxUtils();
    if (modelesRedux?.length <= 0) await saveModelesReduxUtils();
    this.setState({ referencialDataLoaded: true });
  }

  clearCache() {
    const webAppVersion = window.localStorage.getItem(webAppVersionKey);
    if (webAppVersion !== version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();

        localStorage.clear();
        localStorage.setItem(webAppVersionKey, version);
      }
    }
  }

  componentDidMount(): void {
    Events.subscribe(constants.lang_change, ({ lang }) => {
      this.setState({
        lang: lang,
      });
      lang === 'ar' ? (document.documentElement.dir = 'rtl') : (document.documentElement.dir = 'ltr');
    });
  }

  componentWillUnmount(): void {
    window.localStorage.setItem(webAppVersionKey, version);
    Events.destroy(constants.lang_change);
  }

  render() {
    const { lang, referencialDataLoaded } = this.state;
    if (!referencialDataLoaded) {
      return (
        <IonLoading
          cssClass="loading-custom-class"
          isOpen={!referencialDataLoaded}
          onDidDismiss={() => this.setState({ referencialDataLoaded: true })}
          mode="ios"
          spinner={'bubbles'}
          translucent
        />
      );
    } else {
      return (
        <IntlProvider locale={lang} defaultLocale={'ar'} messages={lang === 'ar' ? ar : fr}>
          <CookiesProvider>
            <IonApp>
              {
                // @ts-ignore
                <ErrorBoundary>
                  <Suspense
                    fallback={
                      <IonLoading
                        cssClass="loading-custom-class"
                        isOpen={true}
                        mode="ios"
                        spinner={'bubbles'}
                        translucent
                        duration={800}
                      />
                    }
                  >
                    <IonReactRouter>
                      <AppUrlListener lang={lang} />
                      <MobileMenu />
                      <Navigation lang={lang} />
                    </IonReactRouter>
                  </Suspense>
                </ErrorBoundary>
              }
              {/* {isPlatform('mobileweb') && <CampaignSideBarButton lang={lang} />} */}
              {/* <WebPopupBanner lang={lang} /> */}
              {lang === 'fr' && !isPlatform('capacitor') && (
                <CookieConsent
                  buttonText={'OK'}
                  expires={365}
                  style={{ backgroundColor: 'var(--ion-color-secondary-dark)' }}
                  cookieName="flahaCookie"
                  buttonStyle={{
                    backgroundColor: '#D9A845',
                    color: '#fff',
                    borderRadius: '.5rem',
                  }}
                >
                  <div className="cookie-container">
                    <img src="https://i.imgur.com/Tl8ZBUe.png" width="25" alt="cookies" />
                    <span>
                      Ce site utilise les cookies, merci de consulter notre page{' '}
                      <a href="/privacy">Terms & conditions</a> pour plus de détail
                    </span>
                  </div>
                </CookieConsent>
              )}

              {lang === 'ar' && !isPlatform('capacitor') && (
                <CookieConsent
                  buttonText={'موافق'}
                  expires={365}
                  style={{ backgroundColor: 'var(--ion-color-secondary-dark)' }}
                  cookieName="flahaCookie"
                  buttonStyle={{
                    backgroundColor: '#D9A845',
                    color: '#fff',
                    borderRadius: '.5rem',
                  }}
                >
                  <div className="cookie-container">
                    <img src="https://i.imgur.com/Tl8ZBUe.png" width="25" alt="cookies" />
                    <span>
                      يستخدم هذا الموقع ملفات تعريف الارتباط ، يرجى الاطلاع على صفحة
                      <a href="/privacy"> الشروط والأحكام </a> الخاصة بنا لمزيد من التفاصيل
                    </span>
                  </div>
                </CookieConsent>
              )}
            </IonApp>
          </CookiesProvider>
        </IntlProvider>
      );
    }
  }
}

interface Props extends FlahaProps {
  // regionsRedux: any;
  // citiesRedux: any;
  // categoriesRedux: any;
  marquesRedux: any;
  modelesRedux: any;
}

interface State extends FlahaState {
  lang: 'fr' | 'ar';
  referencialDataLoaded: boolean;
}

// Redux mappers
const mapStateToProps = (state) => {
  return {
    // regionsRedux: state.saveRegionsCategoriesReducer.Regions_Categories.regions,
    // citiesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.cities,
    // categoriesRedux: state.saveRegionsCategoriesReducer.Regions_Categories.categories,
    modelesRedux: state.saveModelesReducer.modeles,
    marquesRedux: state.saveMarquesReducer.marques,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
