import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Post, SubscriptionTracking } from 'utils/Types/types';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  isPlatform,
} from '@ionic/react';
import { ellipsisHorizontal, ellipsisVertical, pencilOutline, rocketOutline, time, trashOutline } from 'ionicons/icons';
import { constants, dateFormatter } from 'utils';
import './AnnonceCard.scss';
import { useHistory } from 'react-router';
import boostService from '../../services/BoostService';
import BoostService from '../../services/BoostService';
import { langFunction } from 'lang';
import BoostAnnonceModalContent from '../BoostAnnonceModalContent/BoostAnnonceModalContent';
import { Menu } from '@material-ui/core';

interface AnnonceCardProps {
  intl: any;
  lang: string;
  post: Post;
  handleDelete: any;
  handleAnnonceClicked: any;
  token: string;
  updateSubscription: any;
}

const AnnonceCard: React.FC<AnnonceCardProps> = ({
  intl,
  lang,
  post,
  handleDelete,
  handleAnnonceClicked,
  token,
  updateSubscription,
}: AnnonceCardProps) => {
  const { id, titreFrancais, titreArabe, prix, premierePhoto, categorie, ville, datePublication, slug, moderate } =
    post;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [tracking, setTracking] = useState<SubscriptionTracking>(undefined);
  const [remainingDays, setRemainingDays] = useState(0);
  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [countTimeLeft, setCountTimeLeft] = useState(null);

  const [period, setPeriod] = useState(1);
  const [boostID, setBoostID] = useState(1);

  const [showBoostAnnonceModal, setShowBoostAnnonceModal] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertOnBoostError, setShowAlertOnBoostError] = useState(false);

  const slicedSlug = slug ? slug.replace(id + '', '_' + id) : '';
  const villeArab = ville.urlArab;
  const villeFrancais = ville.urlFr;
  const regionArab = ville.province.region.urlArab;
  const regionFr = ville.province.region.urlFr;
  const categorieFrancais = categorie.urlFrancais;
  const categorieArab = categorie.urlArabe;

  const [isOpen, setIsOpen] = useState(false);

  let reason = intl.formatMessage(langFunction.annonceDeleteMotif);
  const title = intl.formatMessage(langFunction.annonceDeleteTitle);
  const message = intl.formatMessage(langFunction.annonceDeleteMessage);
  const confirm = intl.formatMessage(langFunction.confirmYes);
  const close = intl.formatMessage(langFunction.close);

  const getTracking = () => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    boostService
      .getAnnonceBoost(String(id), options)
      .then((value) => {
        const { tracking, countTimeLeft, remainingDays, remainingHours, remainingMinutes, remainingSeconds } =
          value.data;

        setTracking(tracking);
        setRemainingDays(remainingDays);
        setRemainingHours(remainingHours);
        setRemainingMinutes(remainingMinutes);
        setRemainingSeconds(remainingSeconds);
        setCountTimeLeft(countTimeLeft);
      })
      .catch((reason) => console.error(`Error has been thrown (${reason.message})`, reason));
  };

  const boostAnnonce = (annonceID, boostID, timeBoost) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      specifiedTimeForBoost: timeBoost || null,
    };

    BoostService.boostAnnonce(annonceID, boostID, data, options)
      .then((value) => {
        setShowAlertSuccess(true);
      })
      .catch((reason) => {
        if (reason.response.status === 406 || reason.response.status === 404) setShowAlertOnBoostError(true);
        else console.error(`Error has been thrown (${reason.message})`, reason);
      });
  };

  const boostAnnonceStop = (annonceID, boostID) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    BoostService.boostAnnonceStop(annonceID, boostID, options)
      .then((value) => {
        setShowAlertSuccess(true);
      })
      .catch((reason) => {
        console.error(`Error has been thrown (${reason.message})`, reason);
      });
  };
  const boostAnnonceStart = (annonceID, boostID) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    BoostService.boostAnnonceStart(annonceID, boostID, options)
      .then((value) => {
        setShowAlertSuccess(true);
      })
      .catch((reason) => {
        console.error(`Error has been thrown (${reason.message})`, reason);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getTracking();
  }, []);

  let history = useHistory();

  return (
    <>
      <IonCard className={'d-flex flex-column card-container'}>
        <div className={'card-top'}>
          <div className={'card-top-overlay'} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <IonItem lines={'full'} href={`/${lang}/user/annonce/edit/${id}`} button>
              <IonIcon slot={'start'} icon={pencilOutline} />
              <FormattedMessage id={'update'} />
            </IonItem>
            <IonItem
              lines={'full'}
              onClick={() => {
                setIsOpen(true);
                handleClose();
              }}
              button
            >
              <IonIcon slot={'start'} icon={trashOutline} />
              <FormattedMessage id={'delete'} />
            </IonItem>
            {!tracking && (
              <IonItem
                color={'primary'}
                lines={'full'}
                onClick={() => {
                  setShowBoostAnnonceModal(true);
                  handleClose();
                }}
                button
              >
                <IonIcon slot={'start'} icon={rocketOutline} />
                <FormattedMessage id={'annonce.boost.label'} />
              </IonItem>
            )}
            {/*TODO
            {tracking && countTimeLeft == 0 && (
              <IonItem
                color={'primary'}
                lines={'full'}
                onClick={() => {
                  boostAnnonceStop(String(id), boostID);
                }}
                button
              >
                <IonIcon slot={'start'} icon={rocketOutline} />
                <FormattedMessage id={'annonce.boost.label.stop'} />
              </IonItem>
            )}
            {tracking && countTimeLeft > 0 && (
              <IonItem
                color={'secondary'}
                lines={'full'}
                onClick={() => {
                  boostAnnonceStart(String(id), boostID);
                }}
                button
              >
                <IonIcon slot={'start'} icon={rocketOutline} />
                <FormattedMessage id={'annonce.boost.label.start'} />
              </IonItem>
            )}*/}
          </Menu>
          <IonButtons className={'card-top-options'}>
            <IonButton onClick={handleClick}>
              <IonIcon slot={'icon-only'} ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <picture className={'card-image'}>
            <source
              type="image/webp"
              srcSet={`${
                premierePhoto && premierePhoto.cheminWebp
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.cheminWebp + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
            />
            <img
              loading="lazy"
              decoding={'async'}
              src={`${
                premierePhoto && premierePhoto.chemin
                  ? process.env.REACT_APP_API_URL + constants.pictures + premierePhoto.chemin + '?small=false'
                  : '/assets/img/no_images.webp'
              }`}
              alt={lang === 'fr' ? titreFrancais + ' - ' + id : titreArabe + ' - ' + id}
            />
          </picture>
          <div className="card-bottom-fixed">
            <div className="card-timestamp">
              <IonIcon className={'mx-2'} icon={time} />
              <span className="card-timestamp-date">{dateFormatter(datePublication, lang)}</span>
            </div>
            {!moderate && (
              <div className={'card-moderate d-flex justify-content-center align-items-center'}>
                {<FormattedMessage id={'post.in.moderation'} />}
              </div>
            )}
            {tracking && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonText color={'primary'} className={'body-bottom-category'}>
                  {lang === 'fr'
                    ? tracking?.boostPlanType?.boostPlan?.labelFr
                    : tracking?.boostPlanType?.boostPlan?.labelAr}
                </IonText>
              </div>
            )}
            {tracking && remainingDays > 0 && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonIcon className={'mx-1 body-bottom-category'} color={'primary'} icon={rocketOutline} />
                <IonText color={'primary'} className={'body-bottom-category'}>
                  <FormattedMessage id={'annonce.boost.left.days'} values={{ days: remainingDays }} />
                </IonText>
              </div>
            )}
            {tracking && countTimeLeft > 0 && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonIcon className={'mx-1 body-bottom-category'} color={'primary'} icon={rocketOutline} />
                <IonText color={'primary'} className={'body-bottom-category'}>
                  <FormattedMessage id={'annonce.boost.stop.label'} values={{ days: remainingDays }} />
                </IonText>
              </div>
            )}
            {tracking && remainingDays === 0 && remainingHours > 0 && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonIcon className={'mx-1 body-bottom-category'} color={'primary'} icon={rocketOutline} />
                <IonText color={'primary'} className={'body-bottom-category'}>
                  <FormattedMessage id={'annonce.boost.left.hours'} values={{ hours: remainingHours }} />
                </IonText>
              </div>
            )}
            {tracking && remainingDays === 0 && remainingHours === 0 && remainingMinutes > 0 && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonIcon className={'mx-1 body-bottom-category'} color={'primary'} icon={rocketOutline} />
                <IonText color={'primary'} className={'body-bottom-category'}>
                  <FormattedMessage id={'annonce.boost.left.minutes'} values={{ minutes: remainingMinutes }} />
                </IonText>
              </div>
            )}
            {tracking && remainingDays === 0 && remainingHours === 0 && remainingMinutes === 0 && remainingSeconds > 0 && (
              <div className={'card-boost-info d-flex justify-content-center align-items-center'}>
                <IonIcon className={'mx-1 body-bottom-category'} color={'primary'} icon={rocketOutline} />
                <IonText color={'primary'} className={'body-bottom-category'}>
                  <FormattedMessage id={'annonce.boost.left.seconds'} values={{ seconds: remainingSeconds }} />
                </IonText>
              </div>
            )}
          </div>
        </div>

        <IonCardHeader
          className={'card-header-bottom'}
          onClick={() => {
            if (isPlatform('capacitor')) {
              handleAnnonceClicked(slug, slicedSlug);
            } else {
              lang === 'ar'
                ? history.push(`/ar/${regionArab}/${villeArab}/${categorieArab}/${slicedSlug}.htm`)
                : history.push(`/fr/${regionFr}/${villeFrancais}/${categorieFrancais}/${slicedSlug}.htm`);
            }
          }}
        >
          <IonCardSubtitle>
            {prix !== 0.0 ? (
              <FormattedNumber
                value={prix}
                style={'currency'}
                currency={'Mad'}
                currencyDisplay={'symbol'}
                maximumFractionDigits={2}
              />
            ) : (
              <FormattedMessage id={'price.negotiable'} />
            )}
          </IonCardSubtitle>
          <IonCardTitle>{lang === 'fr' ? titreFrancais : titreArabe}</IonCardTitle>
        </IonCardHeader>
      </IonCard>
      <IonModal
        isOpen={showBoostAnnonceModal}
        onDidDismiss={() => {
          setShowBoostAnnonceModal(false);
        }}
        cssClass="my-custom-class"
      >
        <BoostAnnonceModalContent
          onClose={() => {
            setShowBoostAnnonceModal(false);
            getTracking();
          }}
          intl={intl}
          lang={lang}
          selectedAnnonceID={id}
          onBoost={boostAnnonce}
          isAnimal={categorie?.animal}
        />
      </IonModal>
      <IonAlert
        id={slug}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        subHeader={title}
        message={`<img src = "./assets/interrogation.jpg" class="img-fluid w-50"/> <div class="text-wrap"> ${message} </div>`}
        inputs={[
          {
            type: 'radio',
            label: intl.formatMessage(langFunction.annonceDeleteMotif),
            value: intl.formatMessage(langFunction.annonceDeleteMotif),
            handler: (e) => {
              reason = e.value;
            },
            checked: true,
          },
          {
            type: 'radio',
            label: intl.formatMessage(langFunction.annonceDeleteMotifTwo),
            value: intl.formatMessage(langFunction.annonceDeleteMotifTwo),
            handler: (e) => {
              reason = e.value;
            },
          },
          {
            type: 'radio',
            label: intl.formatMessage(langFunction.annonceDeleteMotifThree),
            value: intl.formatMessage(langFunction.annonceDeleteMotifThree),
            handler: (e) => {
              reason = e.value;
            },
          },
          {
            type: 'radio',
            label: intl.formatMessage(langFunction.annonceDeleteMotifFour),
            value: intl.formatMessage(langFunction.annonceDeleteMotifFour),
            handler: (e) => {
              reason = e.value;
            },
          },
          {
            type: 'radio',
            label: intl.formatMessage(langFunction.annonceDeleteMotifFive),
            value: intl.formatMessage(langFunction.annonceDeleteMotifFive),
            handler: (e) => {
              reason = e.value;
            },
          },
        ]}
        buttons={[
          {
            text: close,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setIsOpen(false);
            },
          },
          {
            text: confirm,
            handler: () => {
              handleDelete(slug, reason);
            },
          },
        ]}
      />
      <IonAlert
        cssClass={'success-alert'}
        isOpen={showAlertSuccess}
        onDidDismiss={() => {
          updateSubscription();
          getTracking();
          setShowAlertSuccess(false);
          setShowBoostAnnonceModal(false);
        }}
        message={'<img src = "./assets/check.gif" >'}
        buttons={[
          {
            text: intl.formatMessage(langFunction.close),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              updateSubscription();
              getTracking();
              setShowAlertSuccess(false);
              setShowBoostAnnonceModal(false);
            },
          },
        ]}
      />
      <IonAlert
        cssClass={'error-alert'}
        isOpen={showAlertOnBoostError}
        onDidDismiss={() => {
          setShowAlertOnBoostError(false);
          window.location.href = '/user/mes-annonces';
        }}
        message={`<div>${intl.formatMessage({
          id: 'boost.create.message',
        })}</div><div><a href="/paiement-infos">${intl.formatMessage({ id: 'boost.create.payment.link' })}</a></div>`}
        buttons={[
          {
            text: intl.formatMessage(langFunction.close),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowAlertOnBoostError(false);
            },
          },
        ]}
      />
    </>
  );
};

export default AnnonceCard;
